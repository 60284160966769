<template>
  <div class="manager-container  module-main-body" v-loading="isLoading">
    <el-row class="module-content-top-bar">
      <el-col :sm="8" :md="10" :lg="10">
        <h1 class="top-bar-title">Pricing Tier</h1>
      </el-col>
      <el-col :sm="16" :md="14" :lg="14" v-if="isPricingTierEnabled">
        <div class="button-bar flex-end">
          <div class="button-block" style="padding-top:5px">
            <el-button  class="primary-button" @click="save">{{ this.$t('save') }}</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="div-main-body-left-right module-content-main-view">
      <el-col :span="24" style="padding: 20px">
        <div style="height:100%; overflow-y: auto;">
          <div class="flex-end" style="padding-bottom: 20px;">
            <div class="flex" style="padding-right: 10px;">
              <el-date-picker style="width: 100px;" popper-class="month-picker" class="month-picker" type="month" format="MMMM" value-format="MM" :clearable="false" v-model="month" @change="changeMonthHandler"></el-date-picker>
            </div>
            <div class="flex" style="padding-right: 10px;">
              <el-date-picker style="width: 100px;" class="year-picker" type="year" format="yyyy" value-format="yyyy" :clearable="false" v-model="year" @change="changeYearHandler"></el-date-picker>
            </div>
          </div>
          <template>
            <div v-if="isPricingTierEnabled" class="flex-start" style="width:100%; overflow-x: hidden; ">
              <div class="flex" style="width:200px">
                <el-table :data="data" :cell-style="cellStyle" :header-cell-style="headerCellStyle">
                  <el-table-column label="">
                    <template #default="scope">
                      <div v-if="scope.$index != 2"><b>{{ scope.row.title }}</b></div>
                    </template>
                  </el-table-column>
                  <el-table-column label="" width="80">
                    <template #default="scope">
                      <div v-if="scope.$index == 2"><b>{{ scope.row.title }}</b></div>
                      <el-input type="number"  v-else-if="scope.$index > 2 && scope.$index != data.length-1" v-model="scope.row.amount" size="mini" @input="(value) => inputTierHandler(scope.row.tierId, value)" />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="flex" style="overflow-x: auto">
                <el-table :data="data" :cell-style="cellStyle" :header-cell-style="headerCellStyle">
                  <el-table-column v-for=" day in days " width="80" align="center">
                    <template #header>{{ day }}</template>
                    <template #default="scope">
                      <div v-if="scope.$index == 2" class="wrap"></div>

                      <el-input type="number" v-else-if="scope.$index < 2" v-model="marketPriceExchangeRateData[scope.$index][day-1]['amount']" size="mini" @blur="inputPriceOrRateHandler(day-1, day)"/>
                      <el-input-number v-else-if="scope.$index == data.length-1" v-model="dateData[scope.$index - 3][day-1]['amount']" size="mini" :precision="0" :controls="false" style="width: 70px;" @blur="handleInputStdTier($event,day,scope.$index-3,scope.row)"/>
                      <el-input type="number" v-else-if="scope.$index > 2" v-model="dateData[scope.$index - 3][day-1]['amount']" size="mini" :disabled="true" />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div v-else-if="hasCustomerPricingTier">
              <el-calendar v-model="calendarDate">
                <template slot="dateCell" slot-scope="{ data }">
                  <div v-if="data.day.split('-').slice(1,2).join('-') == month">
                    <div style="font-size: 12px; background-color: #DDE4F1; padding: 8px; border-radius: 5px; ">
                      {{ getCurrentDatePringingTier(parseInt(data.day.split("-").slice(2).join("-"))) }}
                    </div>
                    <div class="flex-end"  style="text-align: end; font-weight: bolder;margin-top: 20px;">
                      {{ data.day.split("-").slice(2).join("-") }}
                    </div>
                  </div>
                  <div v-else>
                    <div  style="text-align: end; font-weight: bolder;margin-top: 20px;">
                      {{ data.day.split("-").slice(2).join("-") }}
                    </div>
                  </div>
                </template>
              </el-calendar>
            </div>
            <div v-else >The Pricing Tier Is Not Activated</div>
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'

export default {
  name: "setPricingTier",
  mixins: [baseMixin],
  computed: {
    ...mapState(['currentCompany','currentCompanySettings',]),
    isPricingTierEnabled() {
      return this.currentCompanySettings?.pricingTierCheck || this.currentCompany.isPricingTierEnabled
    },
    hasCustomerPricingTier(){

      return this.currentCompany.victoryCompanyCode || this.currentCompany.terms || this.currentCompany.groupCode || this.currentCompany.tierId
    },
    currentTier() {
      return this.currentCompany.pricingTier
    },
    isLoading(){
      const isLoading =this.fullScreenLoading && ( !!this.isPricingTierEnabled || !!this.hasCustomerPricingTier)
      return isLoading
    }
  },
  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          this.$store.dispatch("getCompanySettings", value);
        }
      },
      immediate: true,
    }
  },
  data() {
    return {
      MANUAL_TIER_NAME: "STD",
      year: '0',
      month: '',
      fullScreenLoading: false,
      days: 0,
      data: [],
      dateData: [],
      saveDateData: {},
      saveTierData: {},
      marketPriceExchangeRateData: [],
      titles: [
        { title: 'Market Price' },
        { title: 'Exchange Rate' },
        { title: 'Add / MT' },
      ],
      tiers: [],
      isFilled: false,
      currentOrgTierData:[],
      stdTierData:[],
      calendarDate: new Date(),
    }
  },
  async created() {
    this.year = this.$moment().year().toString()
    this.month = this.$moment().format('MM')
    this.days = parseInt(this.$moment().endOf('month').format("DD"))
    await this.getPricingTierDefinition()
    await this.getData()
  },
  methods:{
    defaultData() {
      this.marketPriceExchangeRateData = []
      for(let i=0; i<2; i++) {
        let array = []
        for(let day=1; day<=this.days; day++) {
          array.push({ amount : null })
        }
        this.marketPriceExchangeRateData.push( array)
      }
    },
    async getPricingTierDefinition() {
      await this.pricingTierDefinition().then( res => {
        if( res ) {
          res.map( item => {
            this.tiers.push({ tierId : item.id, title : item.name, amount : null })
          })
        }
      })
    },
    async getPricingTierMonthly() {
      this.defaultData()
      const orgId = this.currentCompanySettings.pricingTierCheck ? this.currentCompany.id: this.currentCompany.pricingTierOrganizationId
      await this.pricingTierMonthly(this.year, this.month,orgId).then( res => {
        let _stdTierData =[]
        let stdTierId;

        if( res ) {
          this.pricingTierMonthlyResp = res;
          let data = res?.data
          let tiers = res?.tiers

          if( tiers ) {
            this.tiers.map( item => {
              item.amount = tiers.find( e => e.tierId == item.tierId)?.addMt
              if (item.title == this.MANUAL_TIER_NAME) {
                stdTierId = item.tierId
              }
            })
          }

          if( data ) {

            data.map( item => {
              let day = parseInt(this.$moment( item.date , 'YYYY-MM-DD').format("DD"))
              this.marketPriceExchangeRateData[0][day-1]['amount'] = item.marketPrice
              this.marketPriceExchangeRateData[1][day-1]['amount'] = item.exchangeRate
              if (item.tierId == stdTierId ) {
                const stdTierdateitem ={

                  manualAmount:item?.manualAmount?? undefined,
                  tierId:item?.tierId?? undefined,
                  date:item?.date?? undefined,
                }

                _stdTierData.push(stdTierdateitem)
                this.stdTierData = _stdTierData
              }

            })

          }
        }
        if (this.isPricingTierEnabled) {
          this.data = this.titles.concat(this.tiers)
          this.calculate()
        } else {
          const tier = this.tiers.find( e => e.tierId == this.currentTier);
          // const tier = this.tiers.find( e => e.title == this.currentTier);
          this.currentOrgTierData =  this.calculateByTierAmount(tier,tier.title == 'STD')

        }

      })
    },
    async getData() {
      this.fullScreenLoading = true
      this.isFilled = false
      await this.getPricingTierMonthly()
      this.fullScreenLoading = false
    },

    calculateByTierAmount(tier,isStdTier) {
      let array = []
      for(let day=1; day<=this.days; day++) {
        let _amount = null
        if (isStdTier) {
          const currentDateX = this.getSelectedDate(day)
          const manualAmount = this.stdTierData.find( e=>e.date == currentDateX)?.manualAmount??null
          _amount = manualAmount
          _amount = Math.round(_amount)
        } else {
          let marketPrice = parseFloat(this.marketPriceExchangeRateData[0][day-1]['amount'])
          let exchangeRate = parseFloat(this.marketPriceExchangeRateData[1][day-1]['amount'])
          const addMt = parseFloat(tier.amount)

          if( !Number.isNaN(marketPrice) && !Number.isNaN(exchangeRate) && !Number.isNaN(addMt) ) {
            _amount = this.calculatePricingTier( marketPrice, exchangeRate, addMt )
            _amount = Math.round(_amount)
          }
        }
        array.push({ amount : _amount })
      }
      return array
    },
    calculate() {
      this.dateData = []
      this.tiers.map( item => {
        let array = this.calculateByTierAmount(item,item?.title == this.MANUAL_TIER_NAME)
        this.dateData.push( array )
      })
    },
    async changeMonthHandler(val){
      let date = this.year + '-' + val + '-01'
      this.calendarDate = date
      this.days = parseInt(this.$moment( date , 'YYYY-MM-DD').endOf('month').format("DD"))
      await this.getData()
    },
    async changeYearHandler(val) {
      let date = val + '-' + this.month + '-01'
      this.calendarDate = date
      this.days = parseInt(this.$moment( date , 'YYYY-MM-DD').endOf('month').format("DD"))
      await this.getData()
    },
    inputTierHandler( tierId, value ) {
      const addMt = parseFloat(value)
      this.saveTierData[tierId] = { tierId: tierId, addMt: Number.isNaN(addMt) ? null : addMt }
      this.data.map(item => {
        if(item.tierId == tierId) {
          item.amount = value
        }
      })

      for(let day = 1; day<= this.days; day++ ) {
        let date = this.year + '-' + this.month + '-' + day
        date = this.$moment( date , 'YYYY-MM-D').format("YYYY-MM-DD")
        const marketPrice = parseFloat(this.marketPriceExchangeRateData[0][day-1]['amount'])
        const exchangeRate = parseFloat(this.marketPriceExchangeRateData[1][day-1]['amount'])
        if( !Number.isNaN(marketPrice) && !Number.isNaN(exchangeRate) ) {
          this.saveDateData[date + tierId] = { date: date, marketPrice: marketPrice, exchangeRate: exchangeRate, pricingTierDefId: tierId }
          this.isFilled = true
        }
      }
      this.calculate()
    },
    getSelectedDate(day) {
        let date =this.year + '-' + this.month + '-' + day
        date = this.$moment(date , 'YYYY-MM-D').format("YYYY-MM-DD")
        return date
    },
    inputPriceOrRateHandler( index, day ) {
      let marketPrice = parseFloat(this.marketPriceExchangeRateData[0][index]['amount'])
      let exchangeRate = parseFloat(this.marketPriceExchangeRateData[1][index]['amount'])
      const date = this.getSelectedDate(day)
      if( Number.isNaN(marketPrice) && Number.isNaN(exchangeRate) ) {
        this.tiers.map( item => {
          this.saveDateData[date + item.tierId] = { date: date, marketPrice: "", exchangeRate: "", pricingTierDefId: item.tierId }
          this.isFilled = true
        })
      } else if( !Number.isNaN(marketPrice) && !Number.isNaN(exchangeRate) ) {
        this.tiers.map( item => {
          if( item.amount != null ) {
            this.saveDateData[date + item.tierId] = { date: date, marketPrice: marketPrice, exchangeRate: exchangeRate, pricingTierDefId: item.tierId }
            this.isFilled = true
          }
        })
      }
      this.calculate()
    },
    handleInputStdTier(event , dayX,indexY,row ){
      this.dateData[indexY][dayX-1]['manualAmount']=true
      const manualAmount = Math.round(event.target.value)
      const date = this.getSelectedDate(dayX)
      const exchangeRate = parseFloat(this.marketPriceExchangeRateData[1][dayX-1]['amount'])
      const marketPrice = parseFloat(this.marketPriceExchangeRateData[0][dayX-1]['amount'])

      if(row?.tierId && row?.title==this.MANUAL_TIER_NAME) {
        const {tierId} = row
        this.saveDateData[date + tierId] = { date: date, pricingTierDefId: tierId, manualAmount, exchangeRate, marketPrice }
        this.isFilled = true
      }
    },
    validate() {
      let length = Object.values(this.saveDateData).length

      if( length == 0 || ! this.isFilled ) {
        // error message
        this.$message.error('No Combination Is Add/Update')
        return false
      }

      return true
    },
    save() {
      if( this.validate() ) {
        this.fullScreenLoading = true
        const tierDate = this.month + "/" + this.year
        const tiers = Object.values(this.saveTierData)
        const organizationId = this.currentCompany.id
        const data = Object.values(this.saveDateData)
        const idxStd = this.tiers.findIndex(t=>t.title==this.MANUAL_TIER_NAME)
        if (idxStd!=-1) {
          //
          // const idxStd = this.tiers.findIndex(t=>t.title==this.MANUAL_TIER_NAME)
          if (tiers.findIndex(t=>t.title==this.MANUAL_TIER_NAME) ==-1) {
            tiers.push({
              tierId:this.tiers[idxStd].tierId,
              manualEntry:true,
              addMt:0
            })
          }else{
            const index =tiers.findIndex(t=>t.title==this.MANUAL_TIER_NAME)
            tiers[index]['manualEntry'] = true
          }
        }
        const jsonData = { tierDate, tiers, organizationId, data }
        this.submitPricingTier( jsonData ).then(res => {
          if( res ) {
            this.saveTierData = []
            this.saveDateData = []
            this.isFilled = false
          }
        }).catch((error) => {
          this.$message.error( error )
        }).finally(() => {
          this.fullScreenLoading = false
        })
      }
    },
    cellStyle( data ) {
      return { borderWidth: '0px', height: '46px' }
    },
    headerCellStyle() {
      return { backgroundColor: '#EBEEF5', border: '1px solid #EBEEF5' }
    },
    getCurrentDatePringingTier(dateInt) {
      if (dateInt < 1 || dateInt > 31)return undefined;
      return this.currentOrgTierData[dateInt-1]?.amount ?? '-'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../systemManager/companyManager/index.scss";

.wrap {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid black;
  background: black;
  width: 100%;
  transform: translateY(-50%);
}
.transfer-to-from {
    display: flex;
    justify-items: center;
    justify-content: left;
  .arrow-icon{
    height: 100%;
    display: block;
    margin: auto 0;
  }
}
.el-table::before {
  background-color: white;
}
/deep/.el-calendar__header{
  display:none !important;
}
/deep/.el-calendar-table td {
  pointer-events: none !important;
}
/deep/.el-calendar-table thead th{
  font-weight: bold !important;
}
// /deep/.el-input__inner{
//   padding-left: 5px !important;
//   padding-right:4px !important;
// }
:deep() {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

}

</style>
