import { render, staticRenderFns } from "./TableOrder.vue?vue&type=template&id=294f40ae&scoped=true"
import script from "./TableOrder.vue?vue&type=script&lang=js"
export * from "./TableOrder.vue?vue&type=script&lang=js"
import style0 from "./TableOrder.vue?vue&type=style&index=0&id=294f40ae&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294f40ae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\bunkerchain\\portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('294f40ae')) {
      api.createRecord('294f40ae', component.options)
    } else {
      api.reload('294f40ae', component.options)
    }
    module.hot.accept("./TableOrder.vue?vue&type=template&id=294f40ae&scoped=true", function () {
      api.rerender('294f40ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/components/TableOrder.vue"
export default component.exports